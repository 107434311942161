import React from 'react';
import { InView } from 'react-intersection-observer';
import { Motion, spring, OpaqueConfig } from 'react-motion';
import { styled, StyledProps } from '@glitz/react';
import * as style from 'Shared/Style';
import { Down } from 'Shared/Icon/Arrow_forward';
import Viewport from 'Shared/Viewport';
import keyframes from './keyframes.scss';

type PlainStyleType<TValue> = {
  translateY: TValue;
  opacity: TValue;
};
type StylesType<TValue> = PlainStyleType<TValue>;

const ArrowDown = styled(Down, {
  maxWidth: '3rem',
  maxHeight: '3rem',
  color: style.WHITE,
  fontSize: '2rem',
  transformOrigin: 'center center',
});

const ArrowWrapper = styled.span({
  transformOrigin: 'center center',
  ...style.animation({
    name: keyframes.pulse,
    duration: '2s',
    timingFunction: 'linear',
    iterationCount: 'infinite',
  }),
});

export default styled(({ compose, ...restProps }: StyledProps) => {
  return (
    <Viewport>
      {(isCompact: boolean) =>
        isCompact && (
          <InView rootMargin="-30% 0%">
            {({ inView, ref, entry }) => (
              <Motion
                style={
                  {
                    translateY: spring(inView ? -8 : 0, { stiffness: 80, damping: 16 }),
                    opacity: spring(inView ? 0 : 1, { stiffness: 80, damping: 16 }),
                  } as StylesType<OpaqueConfig>
                }
              >
                {(interpolatedStyle: StylesType<number>) => (
                  <styled.Div
                    {...restProps}
                    innerRef={ref}
                    css={compose()}
                    style={{
                      position: 'absolute',
                      bottom: '1rem',
                      width: '100%',
                      textAlign: 'center',
                      ...(interpolatedStyle.translateY !== 0
                        ? {
                            transform: `translateY(${interpolatedStyle.translateY}px)`,
                            opacity: interpolatedStyle.opacity,
                          }
                        : null),
                    }}
                  >
                    <ArrowWrapper>
                      <ArrowDown />
                    </ArrowWrapper>
                  </styled.Div>
                )}
              </Motion>
            )}
          </InView>
        )
      }
    </Viewport>
  );
});
