import React from 'react';
import { styled } from '@glitz/react';
import { media } from '@glitz/core';
import { Breakpoint } from '@avensia/scope';
import Link from 'Shared/Link';
import MainMenuItemType from './MainMenuItem.type';
import * as style from 'Shared/Style';

type LinkPropType = {
  url: string;
  isActive?: boolean;
  darkMode?: boolean;
};

type ItemPropType = {
  item: MainMenuItemType;
  isActive?: boolean;
  darkMode?: boolean;
};

type PropType = LinkPropType | ItemPropType;

function isLink(props: PropType): props is LinkPropType {
  return 'url' in props;
}

const NotActiveLinkStyled = styled(Link, {
  display: 'block',
  width: '100%',
  ...media(style.mediaMinQueries[Breakpoint.Small], {
    width: 'auto',
  }),
  height: '3em',
  lineHeight: '3em',
  color: style.primaryDark,
  backgroundColor: style.WHITE,
  border: {
    xy: {
      width: 'thin',
      style: 'solid',
      color: style.primaryDark,
    },
  },
  font: {
    weight: 500,
    size: '16px',
  },
  ':hover': {
    backgroundColor: style.primaryDark,
    color: style.WHITE,
    textDecoration: 'none',
  },
});

const Label = styled.span({
  display: 'block',
  height: '3em',
  lineHeight: '3em',
  padding: {
    x: '1em',
  },
  ...media(style.mediaMinQueries[Breakpoint.Small], {
    padding: {
      x: '2em',
    },
  }),
  textTransform: 'uppercase',
});

const ActiveLinkStyled = styled(NotActiveLinkStyled, {
  width: '100%',
  ...media(style.mediaMinQueries[Breakpoint.Small], {
    width: 'auto',
  }),
  backgroundColor: style.primary,
  color: style.WHITE,
  fontWeight: 500,
});

export default class Item extends React.PureComponent<PropType> {
  render() {
    const LinkStyled = this.props.isActive ? ActiveLinkStyled : NotActiveLinkStyled;
    if (isLink(this.props)) {
      return (
        <LinkStyled to={this.props.url}>
          <Label>{this.props.children}</Label>
        </LinkStyled>
      );
    } else {
      const { url, name } = this.props.item;
      return (
        <LinkStyled to={url}>
          <Label>{name}</Label>
        </LinkStyled>
      );
    }
  }
}
