/**
 * @ComponentFor MarketingPageViewModel
 */
import React from 'react';
import MarketingPageViewModelType from './MarketingPageViewModel.type';
import { EpiProperty, epiPropertyValue, Breakpoint } from '@avensia/scope';
import { styled } from '@glitz/react';
import * as style from 'Shared/Style';
import { media } from '@glitz/core';
import { Main, Appearance, Heading } from 'Shared/PageLayout';
import Image from 'Shared/Image';
import connect from 'Shared/connect';
import AltBreadcrumbs from 'Shared/Breadcrumbs/alt';
import SimpleSubMenu from 'SiteLayout/MainMenu/SimpleSubMenu';
import HexagonButton from 'Shared/Button/Hexagon';
import { Appearance as ButtonAppearance } from 'Shared/Button';
import FadeIntoView from 'Shared/Animations/FadeIntoView';
import ScrollDownArrow from 'Shared/Animations/ScrollDownArrow';

type StateType = {
  titles: Array<{ title: string; id: string }>;
  activeTitle: string;
};

type ConnectStateType = {
  currentBreakpoint: number;
};

type PropType = MarketingPageViewModelType &
  ConnectStateType & {
    name: string;
  };

const CenterContent = styled.div({
  width: '100%',
  ...media(style.mediaMinQueries[Breakpoint.Small], {
    textAlign: 'center',
  }),
});

const MainWrapper = styled(Main, {
  width: '100%',
  margin: {
    top: '29px',
    left: 'auto',
    right: 'auto',
  },
  padding: {
    left: '28px',
    right: '28px',
  },
  ...media(style.mediaMinQueries[Breakpoint.Medium], {
    margin: {
      top: '99px',
    },
    padding: {
      left: '4vw',
      right: '4vw',
    },
  }),
  ...media(style.mediaMinQueries[Breakpoint.Large], {
    padding: {
      left: '8vw',
      right: '8vw',
    },
  }),
});

const HeadingWrapper = styled.div({
  ...media(style.mediaMinQueries[Breakpoint.Small], {
    paddingTop: '70px',
    paddingRight: 0,
    paddingBottom: 0,
    paddingLeft: 0,
  }),
});

const BkgPattern = styled.div({
  ...media(style.mediaMinQueries[Breakpoint.Small], {
    backgroundImage:
      'url(' +
      require('../Shared/Image/PatternLeft.png') +
      '), url(' +
      require('../Shared/Image/PatternRight.png') +
      ') ',
    backgroundPosition: 'top left, top right',
    backgroundRepeat: 'repeat-y',
    position: 'absolute',
    top: 0,
    bottom: 0,
    width: '100%',
    zIndex: -1,
  }),
});

const MenuBkg = styled.div({
  position: 'absolute',
  background: style.primaryDark,
  zIndex: -1,
  top: 0,
  width: '100%',
  height: '65px',
  ...media(style.mediaMinQueries[Breakpoint.Medium], {
    height: '80px',
  }),
});

const ImageContainer = styled.div({
  position: 'absolute',
  top: 0,
  width: '100%',
  overflow: 'hidden',
  zIndex: -1,
  height: '560px',
  ...media(style.mediaMinQueries[Breakpoint.Tiny], {
    height: 'calc(850px - 50vw)',
  }),
  ...media(style.mediaMinQueries[Breakpoint.Small], {
    height: '560px',
  }),
});

const BkgImage = styled(Image, {
  width: '100%',
  objectFit: 'cover',
  height: '560px',
  ...media(style.mediaMinQueries[Breakpoint.Tiny], {
    height: 'calc(850px - 50vw)',
  }),
  ...media(style.mediaMinQueries[Breakpoint.Small], {
    height: '560px',
  }),
});

const ImageHeaderContentWrapper = styled.div({
  color: style.WHITE,
  textShadow: '0 0 10px rgba(0,0,0,0.5)',
  margin: {
    bottom: '80px',
    x: 'auto',
  },
  maxWidth: style.maxContentWidth - 480,
  padding: {
    top: '1rem',
    bottom: '0',
  },
  ...media(style.mediaMinQueries[Breakpoint.Small], {
    padding: {
      top: '80px',
    },
  }),
  height: '400px',
  ...media(style.mediaMinQueries[Breakpoint.Tiny], {
    height: 'calc(690px - 50vw)',
  }),
  ...media(style.mediaMinQueries[Breakpoint.Small], {
    height: '450px',
  }),
});

const ImageHeaderFontSize = styled.div({
  fontSize: '1em',
  ...media(style.mediaMinQueries[Breakpoint.Tiny], {
    fontSize: '1.2em',
  }),
});

const ButtonWrapper = styled.div({
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'center',
  margin: {
    top: '2em',
    bottom: '2em',
    right: '-2em',
  },
});

const H1 = styled.h1({
  margin: {
    top: 0,
    bottom: '32px',
  },
  fontWeight: 500,
});

class MarketingPage extends React.Component<PropType, StateType> {
  render() {
    const imageUrl = epiPropertyValue(this.props.content.imageUrl);
    const hasImage = !!imageUrl;
    return (
      <>
        <BkgPattern />
        <MenuBkg />
        {hasImage && (
          <ImageContainer>
            <BkgImage src={epiPropertyValue(this.props.content.imageUrl)} />
            <ScrollDownArrow />
          </ImageContainer>
        )}
        <MainWrapper appearance={[Appearance.BackgroundImage, Appearance.Center]}>
          {hasImage ? (
            this.props.breadcrumbs && <AltBreadcrumbs breadcrumbs={this.props.breadcrumbs} />
          ) : (
            <HeadingWrapper>
              <Heading
                title={
                  epiPropertyValue(this.props.content.pageTitle)
                    ? this.props.content.pageTitle
                    : this.props.content.name
                }
                breadcrumbs={this.props.breadcrumbs}
              />
            </HeadingWrapper>
          )}
          <CenterContent id="mainContent">
            {hasImage && (
              <ImageHeaderContentWrapper>
                <FadeIntoView>
                  <ImageHeaderFontSize>
                    <EpiProperty component={H1} for={this.props.content.pageTitle} />
                    <EpiProperty for={this.props.content.headerContent} />
                  </ImageHeaderFontSize>
                  {epiPropertyValue(this.props.content.headerButtonLink) && (
                    <ButtonWrapper>
                      <HexagonButton
                        appearance={ButtonAppearance.Highlight}
                        to={epiPropertyValue(this.props.content.headerButtonLink)}
                      >
                        <span> {epiPropertyValue(this.props.content.headerButtonText)}</span>
                      </HexagonButton>
                      {epiPropertyValue(this.props.content.headerButton2Link) && (
                        <HexagonButton
                          appearance={ButtonAppearance.Highlight}
                          to={epiPropertyValue(this.props.content.headerButton2Link)}
                        >
                          <span> {epiPropertyValue(this.props.content.headerButton2Text)}</span>
                        </HexagonButton>
                      )}
                      {epiPropertyValue(this.props.content.headerButton3Link) && (
                        <HexagonButton
                          appearance={ButtonAppearance.Highlight}
                          to={epiPropertyValue(this.props.content.headerButton3Link)}
                        >
                          <span> {epiPropertyValue(this.props.content.headerButton3Text)}</span>
                        </HexagonButton>
                      )}
                    </ButtonWrapper>
                  )}
                </FadeIntoView>
              </ImageHeaderContentWrapper>
            )}
            {!epiPropertyValue(this.props.content.hideSubMenu) && <SimpleSubMenu darkMode={false} />}
            <EpiProperty for={this.props.content.mainContent} />
            {!epiPropertyValue(this.props.content.hideSubMenu) && <SimpleSubMenu darkMode={false} />}
          </CenterContent>
        </MainWrapper>
      </>
    );
  }
}

export default connect<ConnectStateType>(state => ({
  currentBreakpoint: state.currentBreakpoint,
}))(MarketingPage);
