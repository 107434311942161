import React from 'react';
import connect from 'Shared/connect';
import MainMenuItemType from 'SiteLayout/MainMenu/MainMenuItem.type';
import { currentUrl, Link, translate } from '@avensia/scope';
import * as style from 'Shared/Style';
import { styled } from '@glitz/react';
import { media } from '@glitz/core';
import { Breakpoint } from '@avensia/scope';
import Item from './SimpleSubItem';
import cleanPath from 'Shared/cleanPath';

export function isRoot(data: MainMenuItemType[] | MainMenuItemType): data is MainMenuItemType[] {
  return Array.isArray(data);
}

type PropType = {
  menuItems: MainMenuItemType[];
  darkMode?: boolean;
};

type DarkModePropType = {
  children: React.ReactNode;
  darkMode?: boolean;
};

const Container = ({ darkMode, children }: DarkModePropType) => (
  <styled.Div
    css={{
      width: '100%',
      display: 'flex',
      flexWrap: 'wrap',
      ...media(style.mediaMinQueries[Breakpoint.Small], {
        flexWrap: 'nowrap',
      }),
      justifyContent: 'center',
      color: darkMode ? style.WHITE : style.primaryDark,
    }}
  >
    {children}
  </styled.Div>
);

const Wrapper = ({ darkMode, children }: DarkModePropType) => (
  <styled.Div
    css={{
      width: '100%',
      color: darkMode ? style.WHITE : style.primaryDark,
      margin: {
        top: '3em',
        bottom: '4em',
      },
    }}
  >
    {children}
  </styled.Div>
);

class SimpleSubMenu extends React.Component<PropType> {
  constructor(props: PropType) {
    super(props);
  }

  render() {
    const currentPath = cleanPath(currentUrl().pathname);
    const rootPath = currentPath.split('/')[0];
    const item = this.props.menuItems.find(i => cleanPath(i.url) === rootPath);
    return item && item.children.length > 0 ? (
      <>
        <Wrapper>
          {currentPath !== rootPath && (
            <Link to={item.url} style={{ display: 'block', marginBottom: '1em' }}>
              {translate('/ContentPage/BackToOverview')}
            </Link>
          )}
          <Container>
            {item.children.map((menuItem, i) => (
              <Item key={i} item={menuItem} isActive={cleanPath(menuItem.url) === currentPath} />
            ))}
          </Container>
        </Wrapper>
      </>
    ) : null;
  }
}

export default connect(state => ({
  menuItems: state.mainMenu.mainMenuPrimaryItems.concat(state.mainMenu.mainMenuSecondaryItems),
}))(SimpleSubMenu);
